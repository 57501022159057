import { emailSecureLink as handleEmailSecureLink, errorEmptyFile } from 'secure-file-share.mod';
import { getDetailButtonCopy } from 'secure-file-share.mod/secure-download-manager.react';

export function emailSecureLink(target){
    const { id, type, size, filename } = target.dataset;

	const updateSfsCount = () => {
		const button = $(`.js-secure-download-detail-button[data-file-id="${ id }"]`);
		if (button) {
			if (button.hasClass('hidden')) {
				button.removeClass('hidden');
			}

			const sfsCountEl = button.find('.sfsCount');
			const sfsDetailCopy = button.find('.sfsDetailCopy');
			if (sfsCountEl) {
				const currentCount = parseInt($(sfsCountEl).text(), 10);
				const newCount = currentCount + 1;
				$(sfsCountEl).text(newCount);
				if (sfsDetailCopy) {
					$(sfsDetailCopy).text(getDetailButtonCopy(newCount));
				}
			}
		}
	};

	if (size === '0') {
		errorEmptyFile({ filename });
	} else {
		handleEmailSecureLink({ id, type, onSfsCreation: updateSfsCount });
	}
}