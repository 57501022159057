import { createStyles, makeStyles } from '@bamboohr/fabric';

export default makeStyles(({ palette }) => {
	return createStyles({
		root: {
		},
		icon: {
			position: 'absolute',
			left: '8px'
		},
		header: {
			paddingLeft: '21px',
			position: 'relative',
			display: 'flex',
			justifyContent: 'space-between',
			'&:hover': {
				'& $icon': {
					'& svg': {
						fill: palette.primary.main,
					}
				},
			},
			cursor: 'pointer'
		},
		closed: {
			height: 0,
			overflow: 'hidden',
		},
		iconOpen: {
			transform: 'rotate(90deg)'
		},
	});
});
