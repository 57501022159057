import { Icon, IconV2, ItemType } from '@bamboohr/fabric';
import { Document19x22, Nub4x10 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import { Collapse } from 'collapse';
import { ReactElement, useEffect, useState } from 'react';
import { EmployeeFolder } from '../../types';
import useStyles from './folder-selector.style';

const DOCUMENT_TAB_ID = 'DOCUMENT_TAB';

interface Props {
	initialSelection: string;
	onChange: (value: string) => void;
	isFolder: boolean;
	movingFolder?: EmployeeFolder;
	folderOptions: ItemType[];
	maxFolderDepth?: number;
	canEditPage?: boolean;
}

export const FolderSelector = (props: Props): JSX.Element => {
	const style = useStyles();
	const { initialSelection, onChange, isFolder, movingFolder, folderOptions, maxFolderDepth, canEditPage } = props;

	const [selectedFolderId, setSelectedFolderId] = useState(initialSelection);

	useEffect(() => {
		onChange(selectedFolderId);
	}, [selectedFolderId]);

	const isRootSelected = selectedFolderId === DOCUMENT_TAB_ID;

	const deriveDisabledState = (folder: ItemType): boolean => {
		if (!isFolder) {
			return false;
		}
		if (movingFolder.id === folder.value) {
			return true;
		}
		if (folder.depth === maxFolderDepth) {
			return true;
		}
		if (+movingFolder.childrenDepth + +folder.depth >= maxFolderDepth) {
			return true;
		}
		return false;
	};

	const renderPanels = (item: ItemType): ReactElement => {
		const hasChildren = !!item.items.length;
		const isRoot = item.depth === 0;
		const isSelected = selectedFolderId === item.value;
		return (
			<Collapse.Panel
				classes={{
					root: classNames({
						[style.collapse]: isRoot,
						[style.subCollapse]: !isRoot,
					}),
					header: style.collapseHeader,
					headerText: classNames(style.collapseTitle, {
						[style.selectedText]: isSelected,
					}),
					icon: classNames(style.collapseIcon, {
						[style.preventRotate]: ifFeature('encore', true, !hasChildren),
						[style.selectedIcon]: isSelected,
					}),
					...ifFeature('encore', { iconOpen: style.rotateChevron }),
					headerDisabled: style.headerDisabled,
					headerTextDisabled: style.headerTextDisabled,
				}}
				disabled={deriveDisabledState(item)}
				endElement={ifFeature(
					'encore',
					hasChildren && <IconV2 color='neutral-extra-strong' name='chevron-right-regular' size={16} />,
					isSelected ? (
						<span className={style.endIcon}>
							<Icon brand={true} name='fab-check-circle-12x12' />
						</span>
					) : null
				)}
				icon={ifFeature(
					'encore',
					<IconV2
						color={isSelected ? 'primary-strong' : 'neutral-strong'}
						name={isSelected ? 'folder-check-solid' : 'folder-regular'}
						size={16}
					/>,
					hasChildren ? '' : <Nub4x10 />
				)}
				key={item.value}
				onOpen={(value) => setSelectedFolderId(value)}
				panelKey={item.value}
				title={item.text}
			>
				<Collapse>{item.items.map(renderPanels)}</Collapse>
			</Collapse.Panel>
		);
	};

	return (
		<div>
			{isFolder && (
				<div
					className={classNames(style.collapseHeader, style.row, style.collapse, style.documentTab, {
						[style.documentTabSelected]: isRootSelected,
						[style.documentTabDisabled]: !canEditPage,
					})}
					onClick={canEditPage ? () => setSelectedFolderId(DOCUMENT_TAB_ID) : () => null}
					onKeyDown={() => null}
					role='button'
					tabIndex={0}
				>
					<span>
						{ifFeature(
							'encore',
							<IconV2
								color={isRootSelected ?  'primary-strong' : 'neutral-medium'}
								name={isRootSelected ? 'file-check-solid' : 'file-regular'}
								size={16}
							/>,
							<Document19x22 />
						)}
						{$.__('Documents Tab')}
					</span>
					{/* @startCleanup encore */}
					{isRootSelected && (
						<span className={style.endIcon}>
							{ifFeature('encore', undefined, <Icon brand={true} name='fab-check-circle-12x12' />)}
						</span>
					)}
					{/* @endCleanup encore */}
				</div>
			)}
			<div className={classNames({ [style.addedPadding]: isFolder })}>
				<Collapse accordian={true}>{folderOptions.map(renderPanels)}</Collapse>
			</div>
		</div>
	);
};
