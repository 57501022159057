import { getDetailButtonCopy, getFileInfo, getSfsData, showSecureDownloadManager } from 'secure-file-share.mod/secure-download-manager.react';

export const secureDownloadDetail = (fileId, fileType, callback) => {
	const fileInfoRequest = getFileInfo(fileId, fileType);
	const sfsDataRequest = getSfsData(fileId, fileType);

	const updateSfsCount = callback && typeof callback === 'function' ? callback : () => {};

	Promise.all([fileInfoRequest, sfsDataRequest])
		.then(([fileInfoRes, sfsDataRes]) => {
			showSecureDownloadManager({
				file: fileInfoRes.data,
				sfsItems: sfsDataRes.data,
				onDisableLink: updateSfsCount,
			});
		})
		.catch((error) => {
			console.error(error);
			window.errorFallBack();
		});
};

window.secureDownloadDetail = secureDownloadDetail;

$('body').off('click.secure-download-detail-button').on('click.secure-download-detail-button', '.js-secure-download-detail-button', (e) => {
	const { fileId, fileType } = e.target.dataset;

	const updateSfsCount = (count) => {
		const button = $(e.currentTarget);
		const sfsCountEl = button.find('.sfsCount');
		const sfsDetailCopy = button.find('.sfsDetailCopy');
		if (sfsCountEl) {
			$(sfsCountEl).text(count);
		}
		if (sfsDetailCopy) {
			$(sfsDetailCopy).text(getDetailButtonCopy(count));
		}
		if (count === 0) {
			button.addClass('hidden');
		}
	};

	secureDownloadDetail(fileId, fileType, updateSfsCount)
});
