import { sprintf } from 'i18next.lib';
import { sumBy, find } from 'lodash';
import { resetBodyStyles } from 'modal-legacy';
import { addFile } from '../upload';
import { ifFeature } from '@bamboohr/utils/lib/feature';

/**
 * Must have the following passed in
 * error.icon = {string} file name of icon
 * error.title = {string} title message
 * error.subText = {string} sub messaging
 * error.fileUrl = {string} file download link
 */
export function getErrorState(error) {
	if (!error.hasOwnProperty('fabIcon')) {
		error.fabIcon = 'unknown-0x0';
	}
	if (!error.hasOwnProperty('encoreIcon') || !error.hasOwnProperty('encoreSize')) {
		error.encoreIcon = 'unknown';
		error.encoreSize = 14;
	}
	let errorHtml = `
		<div class="blankState no-line">
			<div class="blankState__image txtCenter">
			!* if (fabIcon) { *!
				<ba-icon name="!*= fabIcon *!" encore-name="!*= encoreIcon *!" encore-size="!*= encoreSize *!" />
			!* } *!
			</div>
			<p class="title txtCenter">!*= title *!</p>
			<p class="blankState__subText txtCenter">!*= subText *!</p>
			!* if (typeof(fileUrl) != "undefined" && fileUrl != "") { *!
				<div class="footer" ${ifFeature('encore', 'style="margin-top: 12px;"', '')}>
					<a class="fab-Button fab-Button--outline fab-Button--small" target="_blank" rel="noopener noreferrer" href="!*= fileUrl *!">
					<span class="fab-Button__icon fab-Button__icon--left">
					<ba-icon name="fab-download-12x14" encore-name="down-to-line-regular" encore-size="14"></ba-icon>
					</span>!*= $.__('Download') *!</a>
				</div>
			!* } *!
		</div>
	`;

	return microTemplate(errorHtml, error);
}

export function handleStackableModalState(stacked, isOpen, modalOptions) {
	if (stacked) {
		if (isOpen) {
			modalOptions.isOpen = isOpen;
			window.BambooHR.Modal.setState({
				sheetProps: modalOptions
			}, true);
		} else {
			window.BambooHR.Modal.setState({ sheetProps: null }, true);
		}
	} else if (isOpen) {
		if (window.BambooHR.Modal.isOpen()) {
			window.BambooHR.Modal.setState(modalOptions, true);
		} else {
			modalOptions.isOpen = true;
			window.BambooHR.Modal.setState(modalOptions);
		}
	} else {
		window.BambooHR.Modal.setState({ isOpen: false });
		resetBodyStyles();
	}
}

export function resetProcessingButton() {
	$('.ui-dialog-buttonset').find('button.processing').removeClass('processing disabled');
}

export function setupDraggable(element, options = {}) {
	// if we don't have any droppables then just return
	if (!$('.droppable').length) { return; }
	element.draggable({
		revert: true,
		revertDuration: 350,
		helper: function (event, ui) {
			const selected = $('.draggable.checked');
			const count = selected.size();
			if (count > 1) {
				const move = sprintf($.__n("Move %1 Document", "Move %1 Documents", count), count);
				return $("<div class='dragMultiple'><img src='/images/files/files-drag.png'><span>" + move + "</span></div>");
			}

			if (!$(this).hasClass('checked')) {
				$(this).addClass('checked');
				$(this).find('input[type=checkbox]').attr('checked', 'checked');
			}
			const elm = $(this).clone();
			elm.find('.file-checkbox').remove();
			elm.find('.file-data p, .file-data span').hide();
			elm.find('.file-options').remove();
			elm.find('.file-icon img').css('height', '20px').css('width', '16px');
			elm.css('width', 'auto').wrap("<div/>").removeClass('checked');
			return elm;
		},
		cancel: '.js-not-draggable',
		cursorAt: { left: -1, top: -1 },
		cursor: "pointer",
		...options
	});
}

export function setupDroppable(element, employee) {
	element.droppable({
		activeClass: "",
		hoverClass: "sectionLinkHover",
		tolerance: "pointer",
		drop: function (event, ui) {
			ui.draggable.draggable("option", "revert", false);
			const selected = $('tr.checked');
			const $destinationFolder = $(this).find('.js-FileFolder');
			const sectionId = $destinationFolder.data('section_id');
			if (sectionId && selected.closest('li').data('section_id') != sectionId) {
				const fileRow = selected.clone();
				$('.ui-draggable-dragging').remove();
				const folderId = sectionId;
				if ($destinationFolder.attr('data-shared') == 'yes' && !employee) {
					const html = $('#addToSharedFolderTemplate').tmpl({});
					window.BambooHR.Modal.setState({
						dangerousContent: html.html(),
						primaryAction: () => {
							addFile(folderId, fileRow);
							window.BambooHR.Modal.setState({isOpen: false});
						},
						primaryActionText: $.__('Add File to Shared Folder?'),
						isOpen: true,
						title: $.__('Create Shared File')
					});
				} else {
					addFile(sectionId, fileRow, employee);
				}
			}
		}
	});
}

export function setupShareInputs(context) {
	// taken from reports - need to update for files
	// and make sure we deal with the all employees checkbox

	/**
	 * Choose recipients for share of new OR existing file (determine using context)
	 */
	const selector = context + ' .input-list';
	$(selector).autoInputList({
		autocompleteOptions: {
			highlight: BambooHR.Utils.highlightTermInMarkup
		},
		autocompleteUrl: '/quicksearch/share/file',
		description: $.__('Enter names or groups...'),
		formatAutoItem: qsWithPhoto,
		formatTagItem: function (event, data, formatted) {
			if (data) {
				$('<span class="item">' + data[0] +
					'<input type="hidden" name="ids[' + data[1] + '][]" value="' + data[2] + '" />' +
					'<button class="js-removeItem removeItem fab-FloatingIconButton fab-FloatingIconButton--teenie fab-FloatingIconButton--secondary" type="button">' +
					'<span class="fab-FloatingIconButton__icon removeItemIcon"><ba-icon name="fab-x-10x10" encore-name="circle-x-solid" encore-size="16"></ba-icon></span></button></span>')
					.insertBefore('.input-list span.input');
				$(this).val('');
				$('.input-list:not(.disabled)').on('click', '.js-removeItem', function () {
					$(this).closest('span.item').remove();
					$('.free', context).focus();
					return false;
				});
			}
		}
	}).focus(function () {
		$('.input-list:not(.disabled)', context).addClass('focus');
	}).blur(function () {
		$('.input-list', context).removeClass('focus');
	});

	$('.free', context).addClass('fab-TextInput fab-TextInput--width100');

	$('.input-list:not(.disabled) .description', context).bind('click.autoInit.bhr', function () {
		if ($(context).find('input#allEmployees').attr('checked')) {
			return;
		}
		$('.input-list .description', context).hide().next('.inputs').show();
		$('.input-list', context).addClass('shown');
		$('.free', context).focus();

		$('.js-simplemodal-button-primary, .ui-dialog-buttonset .btn span.ui-button-text').text($.__('Share'));
		const cancelLink = $('<span class="cancel-option"><a href="#">' + $.__("Cancel") + '</a></span>');
		if ($('.js-simplemodal-cancel').length) {
			$('.js-simplemodal-cancel').show();
		} else if (!$(".ui-dialog-buttonset > .cancel-option").length) {
			$('.ui-dialog-buttonset .btn').after(cancelLink);
		}
		$('.js-simplemodal-cancel, .ui-dialog-buttonset .cancel-option').click(function () {
			$('.inputs .item').remove();
			$('.js-simplemodal-button-primary, .ui-dialog-buttonset .btn span.ui-button-text').text($.__('Done'));
			$('#notifyLabel').hide();
			$('.input-list.shown').removeClass('shown');
			$('.input-list .description').show().next('.inputs').hide();
			if ($(this).hasClass('cancel-option')) {
				$(this).remove();
			} else {
				$(this).hide();
			}
			return false;
		});
	});

	$('.input-list:not(.disabled)').click(function () {
		$('.free').focus();
	});

	const acInput = $('.shareForm .input input');
	const forInputSize = $('.forInputSize');
	forInputSize.css({ 'font-size': acInput.css('font-size'), 'font-family': acInput.css('font-family') });
}

/**
 * Shows an image after it is loaded, and removes the corresponding loader
 *
 * @param {element} image This is the image to show
 *
 * return {undefined}
 */
export function showImage(image) {
	if (image.clientHeight > image.clientWidth) { // Taller
		$(image).addClass('image--tall');
	} else {
		$(image).addClass('image--wide');
	}

	image.style.opacity = '1';
	$('#loadingMsg').remove();
}

export function updateButtonForProcessing() {
	$('.ui-dialog-buttonset').find('button.btnAction').addClass('processing disabled');
}

export function updateFileSectionCounts(employeeId) {
	let url = '/ajax/files/get_file_counts.php';
	if (employeeId) {
		url = '/employees/files/getFileCounts/' + employeeId;
	}
	$.ajax({
		url: url,
		dataType: 'json',
		success: function (sections) {
			const EMPLOYEE_UPLOAD = 'employee_upload';
			const signatureUrl = '/files/loadFiles?sectionId=signature&offset=0';
			const sectionName = document.querySelector('#section-name .name');
			const curSectionId = sectionName ? sectionName.dataset.fileSectionId : -1;
			const $fileSections = $('#file-sections .sectionLink');
			let total = null;

			$fileSections.each((index, fileSection) => {
				const $sectionLink = $(fileSection);
				const fileSectionId = $sectionLink.data('section_id') || $sectionLink.find('[data-section_id]').data('section_id');
				const fileSectionType = $sectionLink.data('section_type') || $sectionLink.find('[data-section_type]').data('section_type');
				const section = find(sections, (section) => {
					return fileSectionId === Number(section.id);
				}) || false;
				if (section) {
					section.id = Number(section.id);
					section.count = Number(section.count);
				} else {
					return;
				}
				if (
					window.isViewingSelfAsEss &&
					(
						(
							section &&
							section.section_type === EMPLOYEE_UPLOAD
						) ||
						fileSectionType === EMPLOYEE_UPLOAD
					)
				) {
					$sectionLink.toggleClass('hidden', section ? section.count === 0 : 0);
				}
				$sectionLink.find('.icon span.count, .js-FileFolder__count').text(`(${ section ? section.count : 0 })`);
				if (section.id === Number(curSectionId)) {
					total = section.count;
				}
			});

			if (typeof curSectionId === 'string') {
				if (curSectionId === 'all') {
					updateHeadingCount(sumBy(sections, (section) => {
						return parseInt(section.count)
					}));
				} else if (curSectionId === 'signature') {
					// It's gross, but it works
					$.get(signatureUrl, (data) => {
						return updateHeadingCount(data.sectionFileCount)
					});
				} else {
					updateHeadingCount(total);
				}
			}
			if ('determineBlankState' in window) {
				window.determineBlankState();
			}
		}
	});
}

export function updateHeadingCount(total) {
	if (total !== null) {
		$('.heading-filter #section-name .count').text(`(${ total })`);
	}
}
