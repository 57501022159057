export function downloadEmployeeFiles() {
	// future when we do the employee tab
	const files_base_url = "/employees/files/";
	downloadMultipleFiles(files_base_url);
}

export function downloadCompanyFiles() {
	const files_base_url = "/files/";
	downloadMultipleFiles(files_base_url);
}

export function downloadMultipleFiles(url) {
	let count = 0;
	let get_file_ids = [];
	const now = new Date();
	$(".file-checkbox input:checkbox:checked").each(function () {
		get_file_ids.push(getFileId($(this).attr('id')));
		count++;
	});
	if (count > 0) {
		window.location = url + "download.php?stop=stop&file_ids=" + get_file_ids.join() + "&t=" + now.getTime();

	}
	return false;
}
