import './_secure-download-manager';
import './styles';

const bamboo = window.BambooHR;

bamboo.Components = bamboo.Components || {};

import AutoActioner from 'AutoActioner.mod';
bamboo.Components.AutoActioner = AutoActioner;

import * as filesTab from './files-tab';

// "To be clear, this is not the desired end result, and we need to boy-scout these files in the future"
// We are adding all functions in this file to the window as the feature was written originally with all of these
// functions directly on the window... Read the first part of this comment area for more information ;)
Object.assign(window, filesTab);

window.Files = window.Files || {};

import { SendSignatureRequest } from './_send-signature-request.js';
window.Files.SendSignatureRequest = SendSignatureRequest;

import { GearIcon } from './_gear-icon.js';
window.Files.GearIcon = GearIcon;

// These functions needs to be available on the window for some twig files
import { updateFileUploadActions, centerFileUploadActions } from 'FileManager.mod';
window.updateFileUploadActions = updateFileUploadActions;
window.centerFileUploadActions = centerFileUploadActions;
