/**
 * JS for the gear icon on the files tab
 */
export let GearIcon = {
	/**
	 * selectors
	 */
	_selectors: {
		template: '.template'
	},

	/**
	 * Update the gear icon after signature setup to show the request signature item instead
	 *
	 * @param {int} fileId     The file ID of the original file that was converted
	 * @param {int} workflowId The workflow ID for the template
	 *
	 * @returns {undefined}
	 */
	updateSignatureItem: function(fileId, workflowId, fileName, numberOfSigners, templateId) {
		var $row = $('tr[data-file_id="' + fileId + '"]');
		$row.find('li' + this._selectors.template).html('<a onclick="Files.SendSignatureRequest.openModal(\'' + workflowId + '\', \'' + $.__('Send Signature Request') + '\', \'' + fileName + '\', \'' + numberOfSigners + '\', ' + templateId + ')">' + $.__("Request Signature") + '...</a>');
	}
};
