/**
 * Trigger an action named in url params and defined with data-auto-action-*
 * attributes
 *
 * Useful when you need to have an event automatically triggered. E.g.. When
 * clicking a 'Sign' link from email, we need to direct the user to the inbox
 * and then open the signing modal. By passing some params like:
 *
 *  	`index.php?auto-action=openmodal`
 *
 * we can simulate a click on an element with the `data-auto-action-openmodal`
 * attribute set to the desired action:
 *
 *		<div data-auto-action-openmodal="click">Sign</div>
 *
 * and _click_ the 'Sign' button for the user on document ready.
 *
 * Requires:
 *  	jQuery
 *  	BambooHR.Utils.getParameterByName
 */
import { getParameterByName, sanitizeHtml } from 'BambooHR.util';

/**
 * defaults
 */
var options = {
	name: 'auto-action' // _get param for name
};

/**
 * change defaults
 */
function setOptions(opts) {
	$.extend(options, opts);
}

/**
 * auto-action
 */
function action() {
	// ensure the url has an {options.name} parameter
	var name = getParameterByName(options.name);
	// Had this call originally as part of the initial setting if variable but it did not work as expected
	name = sanitizeHtml(name);
	if (!name) {
		return;
	}
	// ensure the auto-action exists on the page
	var attr = 'data-auto-action-' + name;
	var els = $('[' + attr + ']');
	if (els.length < 1) {
		return;
	}

	$.each(els, function(i, el) {
		$(el).trigger(el.getAttribute(attr));
	});
}

/**
 * document ready
 */
$(function() {
	action();
});

/**
 * reveal
 */

export default {
	setOptions: setOptions
};
