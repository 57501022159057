import { showSlidedown } from 'Alerts.mod';
import Ajax from '@utils/ajax';
export async function kickOffDataCleaner(target) {
    const fileId = target.getAttribute("data-file-id");
    const fileSectionName = target.getAttribute("data-file-section-name");
    let http;
    let errored = false;
    try {
        http = await Ajax.post('/data-cleaning/trigger-cleaner', {
            "file_id": fileId,
            "file_section_name": fileSectionName,
        });
    } catch (e) {
        errored = true;
    }
    if (http?.status === 201 && !errored) {
        showSlidedown($.__('Data cleaning has begun for this file. The data cleaner will be available shortly.'), 'success');
    } else if (http?.status === 204 && !errored) {
        showSlidedown($.__('Sorry, this file isn\'t a valid file to be cleaned.'), 'error');
    } else {
        showSlidedown($.__('Sorry, something went wrong'), 'error');
    }
}