import { ifFeature } from '@bamboohr/utils/lib/feature';
import { handleStackableModalState, setupShareInputs } from './util/helper';
import Ajax from '@utils/ajax';
import { isEnabled } from 'FeatureToggle.util';

export function shareEmployeeFile(id, share, stacked) {
	stacked = stacked == "stacked";
	share = share ? share : false;
	let shareText = $.__("Unshare");

	if (share === "share") {
		shareText = $.__("Share");
	}
	const url = "/ajax/dialog/" + appPrefix + "employee_files/unshare_file.php?share=" + share + "&id=" + id;
	handleStackableModalState(stacked, true, { isLoading: true });
	Ajax.get(url).then(({ data }) => {
		const overlayOptions = {
			alternativeAction: () => {
				handleStackableModalState(stacked, false);
			},
			dangerousContent: data,
			isLoading: false,
			primaryAction: () => {
				$.post(
					url,
					$('#shareForm').serialize(),
					function (result) {
						if (result.ret == "SAVED") {
							handleStackableModalState(stacked, false);
							setMessage(result.message, 'success');
							loadSectionFiles(result.section_id, $('select[name=sort-files]').val());
						}
					},
					'json'
				);
			},
			primaryActionText: shareText,
			secondaryAction: null,
			title: shareText,
			type: (stacked) ? 'smallSheet' : 'small'
		};

		handleStackableModalState(stacked, true, overlayOptions);

	});
	return false;
}

export function shareFile(fileId, stacked, name = '') {
	const row = $('tr[data-file_id=' + fileId + ']');
	const fileName = row.data('filename') || name;
	const url = '/ajax/' + appPrefix + 'files/share_file.php?id=' + fileId;

	function toggleInputOnCheck(ele) {
		if ($(ele).find('tr.allEmployeesRow').length > 0) {
			$('input#allEmployees').attr('checked', 'checked');
			$('#shareInput input').attr('disabled', 'disabled');
			$('.shareListInputLabel').addClass('disabled');
			$('#shareInput').addClass('disabled');
		} else {
			$('#shareInput input').attr('disabled', null);
			$('.shareListInputLabel').removeClass('disabled');
			$('#shareInput').removeClass('disabled');
		}
	}

	
	handleStackableModalState(false, true, { isLoading: true });
	Ajax.get(url).then(({ data }) => {
		let html = document.createElement('div');
		html.innerHTML = data;
		const footerHtml = $(html).find('.js-footerContent').remove().html();

		const primaryAction = () => {
			window.BambooHR.Modal.setState({ isProcessing: true }, true);
			$.post(url, $('form#shareForm').serialize(), function (data) {
				if (data.success) {
					if (data.shared == 'shared') {
						$('.js-fileRow[data-file_id=' + fileId + '] .js-sharedData').removeClass('hidden');
					} else {
						$('.js-fileRow[data-file_id=' + fileId + '] .js-sharedData').addClass('hidden');
					}
					window.BambooHR.Modal.setState({ isProcessing: false }, true);
					handleStackableModalState(false, false);
					if (data.msg !== '') {
						setMessage(data.msg, 'success');
					}
				} else {
					window.BambooHR.Modal.setState({ isProcessing: false }, true);
					setMessage(data.error, 'error');
				}
			}, 'json');
		};
		const modalOptions = {
			alternativeAction: () => {
				handleStackableModalState(false, false);
			},
			contentHasPadding: ifFeature('encore', true, false),
			dangerousContent: html,
			footer: <div className="js-footerOptions fileModalFooterOptions"></div>,
			headline: null,
			icon: null,
			isLoading: false,
			onClose: () => {
				handleStackableModalState(false, false);
			},
			onOpen: () => {
				const $footer = $('.js-footerOptions');
				$footer.html(footerHtml);

				// load the dialog with who is already sharing
				$('#sharedWith', '.sharedFileTableWrapper').load('/ajax/' + appPrefix + 'files/shared_with.php?id=' + fileId + '&type=file', {}, function () {
					toggleInputOnCheck(this);
				});

				$footer.find('input[name="name"]').on('keyup', function () {
					if ($(this).val() !== '') {
						$('.ui-button.create').button('enable');
					}
				});

				$footer.find('input#allEmployees').on('change', function () {
					const postData = {
						'action': 'share',
						'allEmployees': this.checked ? 'on' : 'off'
					};
					$.post(
						'/ajax/' + appPrefix + 'files/share_file.php?id=' + fileId,
						postData,
						function (data) {
							if (data.success) {
								$('#sharedWith').load('/ajax/' + appPrefix + 'files/shared_with.php?id=' + fileId + '&type=file', {}, function () {
									toggleInputOnCheck(this);
								});
								$('.input-list .item').remove();
								$('.cancel-option').remove();
								$('.input-list .description').show().next('.inputs').hide();
								$('.input-list.shown').removeClass('shown');
								$('#notifyLabel').hide();
								if (data.shared == 'shared') {
									$('.js-fileRow[data-file_id=' + fileId + '] .js-sharedData').removeClass('hidden');
								} else {
									$('.js-fileRow[data-file_id=' + fileId + '] .js-sharedData').addClass('hidden');
								}

								const sharedOptions = {
									primaryAction: () => {
										handleStackableModalState(false, false);
									},
									primaryActionText: $.__('Done')
								};

								handleStackableModalState(false, true, sharedOptions);

								if (data.msg !== '') {
									setMessage(data.msg, 'success');
								}
							} else {
								setMessage(data.error, 'error');
							}
						},
						'json'
					);
				});
				setupShareInputs($footer.selector);
				$footer.find('.input-list').addClass('fab-TextInput fab-TextInput--width100');

				$('.js-autoListInput', $footer).click(() => {
					if ($footer.find('input#allEmployees').attr('checked') || $('#shareInput .item').length > 0) {
						return;
					}

					handleStackableModalState(false, true, {
						primaryActionText: modalOptions.primaryActionText,
						primaryAction: primaryAction
					});
				});
			},
			primaryAction: () => handleStackableModalState(false, false),
			primaryActionText: $.__('Done'),
			secondaryAction: null,
			title: $.__('Share %1', fileName),
			type: (stacked) ? 'smallSheet' : 'small'
		};

		handleStackableModalState(false, true, modalOptions);
		modalOptions.onOpen();
	});
}

export function shareFolder(fileSectionId, name) {
	let data = {};
	const html = $('#shareFileSectionTemplate').tmpl(data);

	function toggleInputOnCheck(ele) {
		if ($(ele).find('tr.allEmployeesRow').length > 0) {
			$('input#allEmployees').attr('checked', 'checked');
			$('#shareInput input').attr('disabled', 'disabled');
			$('.shareListInputLabel').addClass('disabled');
			$('#shareInput').addClass('disabled');
		} else {
			$('#shareInput input').attr('disabled', null);
			$('.shareListInputLabel').removeClass('disabled');
			$('#shareInput').removeClass('disabled');
		}
	}

	let modalHtml = document.createElement('div');
	modalHtml.innerHTML = html.html();
	const footerHtml = $(modalHtml).find('.js-footerContent').remove().html();
	const primaryAction = () => {
		window.BambooHR.Modal.setState({ isProcessing: true }, true);
		$.post('/ajax/' + appPrefix + 'files/share_folder.php?id=' + fileSectionId, $('form#shareForm').serialize(), function (data) {
			if (data.success) {
				if (data.shared === true) {
					$('.js-FileFolder[data-section_id=' + fileSectionId + ']').parent().addClass('shared');
				} else {
					$('.js-FileFolder[data-section_id=' + fileSectionId + ']').parent().removeClass('shared');
				}
				window.BambooHR.Modal.setState({ isOpen: false });
				if (data.msg !== '') {
					setMessage(data.msg, 'success');
				}
			} else {
				window.BambooHR.Modal.setState({ isProcessing: false }, true);
				setMessage(data.error, 'error');
			}
		}, 'json');
	};
	const primaryActionText = $.__('Share');
	window.BambooHR.Modal.setState({
		contentHasPadding: ifFeature('encore', true, false),
		dangerousContent: modalHtml,
		footer: <div className="js-footerOptions fileModalFooterOptions"></div>,
		onClose: () => {
			window.BambooHR.Modal.setState({
				isOpen: false
			});
		},
		onOpen: () => {
			const $footer = $('.js-footerOptions');
			$footer.html(footerHtml);

			// load the dialog with who is already sharing
			$('#sharedWith', '#fabricModalContent').load('/ajax/' + appPrefix + 'files/shared_with.php?id=' + fileSectionId + '&type=folder', {}, function () {
				toggleInputOnCheck(this);
			});
			$footer.find('input#allEmployees').on('change', function () {
				const postData = { 'action': 'share', 'all_employees': this.checked ? "true" : "false" };
				$.post('/ajax/' + appPrefix + 'files/share_folder.php?id=' + fileSectionId, postData, function (data) {
					if (data.success) {
						$('#sharedWith').load('/ajax/' + appPrefix + 'files/shared_with.php?id=' + fileSectionId + '&type=folder', {}, function () {
							toggleInputOnCheck(this);
						});
						$('.input-list .item').remove();
						$('.cancel-option').remove();
						$('.input-list .description').show().next('.inputs').hide();
						$('.input-list.shown').removeClass('shown');
						$('#notifyLabel').hide();

						// if we have any tt to update do it here
						if (data.shared === true) {
							$('.js-FileFolder[data-section_id=' + fileSectionId + ']').parent().addClass('shared');
						} else {
							$('.js-FileFolder[data-section_id=' + fileSectionId + ']').parent().removeClass('shared');
						}

						window.BambooHR.Modal.setState({
							primaryActionText: $.__('Done'),
							primaryAction: () => { window.BambooHR.Modal.setState({ isOpen: false }); }
						}, true);

						if (data.msg !== '') {
							setMessage(data.msg, 'success');
						}

					} else {
						setMessage(data.error, 'error');
					}
				}, 'json');
			});
			setupShareInputs($footer.selector);
			$footer.find('.input-list').addClass('fab-TextInput fab-TextInput--width100');

			$('.js-autoListInput', $footer).click(() => {
				if ($footer.find('input#allEmployees').attr('checked') || $('#shareInput .item').length > 0) {
					return;
				}

				window.BambooHR.Modal.setState({
					primaryActionText: primaryActionText,
					primaryAction: primaryAction
				}, true);
			});
		},
		primaryAction: null,
		primaryActionText: primaryActionText,
		isOpen: true,
		title: $.__('Share %1 Folder', name)
	});
}

export function unshareFileOrFolder(type, id, shareType, shareId, $row) {
	if (type == 'file') {
		$.post(
			'/ajax/' + appPrefix + 'files/share_file.php?id=' + id,
			{
				action: 'unshare',
				type: shareType,
				shareId: shareId
			},
			function (data) {
				if (data.success) {
					if (data.shared == "shared") {
						$('tr[data-file_id=' + id + ']').find('span.shared.icon').show();
					} else {
						$('tr[data-file_id=' + id + ']').find('span.shared.icon').hide();
					}
					$row.remove();
					if (shareType == "all_employees") {
						$('#allEmployees').removeAttr('checked');
						$('#shareInput input').attr('disabled', null);
						$('.shareListInputLabel').removeClass('disabled');
						$('#shareInput').removeClass('disabled');
					}
				} else {
					setMessage(data.error, 'error');
				}

			},
			'json'
		);
	} else if (type == 'folder') {
		$.post('/ajax/' + appPrefix + 'files/share_folder.php?id=' + id, { action: 'unshare', type: shareType, shareId: shareId }, function (data) {
			if (data.success) {
				if (data.shared === true) {
					$('li[data-section_id=' + id + ']').addClass('shared');
				} else {
					$('li[data-section_id=' + id + ']').removeClass('shared');
				}
				$row.remove();
				if (shareType == "all_employees") {
					$('#allEmployees').removeAttr('checked');
					$('.inputListWrapper .disabled').removeClass('disabled');
					$('#shareInput input').attr('disabled', null);
				}
			} else {
				setMessage(data.error, 'error');
			}

		}, 'json');
	}
}
